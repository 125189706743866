import React, { useContext } from "react"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Panel from "@bankiru/ui-kit/core/Panel"
import Text from "@bankiru/ui-kit/core/Text"
import IconBackplate from "@bankiru/ui-kit/core/IconBackplate"
import { data } from "./data"
import PageContext from "../../context/context"

export const About = () => {
  const { isDesktop, isTablet, isMobile } = useContext(PageContext)

  return (
    <FlexboxGrid
      gap={isMobile ? "small" : "default"}
      isEqualWidth
      direction={isMobile ? "vert" : "row"}
    >
      {data.map(({ title, icon, mobileIcon, skin, text }) => (
        <Panel
          key={text}
          sections={
            <FlexboxGrid direction="vert" gap={isDesktop ? "medium" : "small"}>
              <IconBackplate size={isMobile ? "h40" : "h56"} icon={isMobile ? mobileIcon : icon} colorBg={skin} colorIcon="mono" />
              <FlexboxGrid direction="vert" gap={isMobile ? "xs" : "small"}>
                <Text
                  tagName="h2"
                  weight="bold"
                  size={isMobile ? "fs16" : isTablet ? "fs18" : "fs24"}
                >
                  {title}
                </Text>
                <Text size={isMobile ? "fs14" : "fs16"} color="secondary">
                  {text}
                </Text>
              </FlexboxGrid>
            </FlexboxGrid>
          }
        />
      ))}
    </FlexboxGrid>
  )
}
