import React, { useContext } from "react"
import image from "../../assests/images/shield.png"
import { StyledBackground } from "./index.styled"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Text from "@bankiru/ui-kit/core/Text"
import PageSection from "@bankiru/ui-kit/core/PageSection"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import PageContext from "../../context/context"

export const PersonalData = () => {
  const { isDesktop, isTablet, isMobile } = useContext(PageContext)

  return (
    <StyledBackground>
      <LayoutWrapper>
        <PageSection indentTop={"medium"} indentBottom="medium">
          <FlexboxGrid
            direction={isMobile ? "vert" : "row"}
            isEqualWidth
            alignItems="center"
            gap={isMobile ? "zero" : "default"}
          >
            <FlexboxGrid
              direction="vert"
              gap={isDesktop ? "default" : isTablet ? "medium" : "small"}
            >
              <Text
                tagName="h2"
                size={isMobile ? "fs16" : isTablet ? "fs24" : "fs32"}
                weight="bold"
              >
                Персональные данные и&nbsp;безопасность
              </Text>
              <FlexboxGrid direction="vert" gap="medium">
                <Text size={isMobile ? "fs14" : "fs16"}>
                  АО&nbsp;&laquo;Банки.ру Маркетплейс&raquo; внесен в&nbsp;реестр
                  операторов персональных данных Роскомнадзора Приказ
                  &#8470;&nbsp;113 от&nbsp;11.07.2022г.
                </Text>
                <Text size={isMobile ? "fs14" : "fs16"}>
                  Страница в&nbsp;Федеральной службе по&nbsp;надзору
                  в&nbsp;сфере связи, информационных технологий и&nbsp;массовых
                  коммуникаций
                  <br />
                  АО&nbsp;&laquo;Банки.ру Маркетплейс&raquo; соответствует ГОСТ
                  Р&nbsp;57580.1-2017&nbsp;г. Защита информации финансовых
                  организаций. Базовый состав организационных и&nbsp;технических
                  мер.
                </Text>
              </FlexboxGrid>
            </FlexboxGrid>
            <FlexboxGrid justifyContent="center" alignItems="center">
              <img
                width={isDesktop ? 380 : 334}
                height={isDesktop ? 380 : 334}
                src={image}
                alt="щит"
              />
            </FlexboxGrid>
          </FlexboxGrid>
        </PageSection>
      </LayoutWrapper>
    </StyledBackground>
  )
}
