import React from "react"

export const partners = [
  {
    data: (
      <>
        &mdash;&nbsp;ПАО&nbsp;&laquo;Уральский банк реконструкции и развития&raquo;,
        ул. 8 Марта, д. 51, эт. 11,  г. Екатеринбург, 620063, ИНН 6608008004,
        ОГРН 1026600000350
      </>
    ),
    id: "rekonstruksciiuralbank",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ПАО КБ&nbsp;&laquo;Центр-инвест&raquo;, проспект Соколова,
        62, г. Ростов-на-Дону, Россия, 344000, ИНН 6163011391, ОГРН
        1026100001949
      </>
    ),
    id: "centrinvest",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ПАО АКБ &laquo;Металлинвестбанк&raquo;&nbsp;&mdash; ул.
        Большая Полянка, д.&nbsp;47, стр.&nbsp;2, г. Москва, 119180, ИНН
        7709138570, ОГРН 1027700218666
      </>
    ),
    id: "metallinvestbank",
  },
  {
    data: (
      <>
        &mdash;&nbsp;&laquo;Азиатско-Тихоокеанский Банк&raquo; (АО)&nbsp;&mdash;
        ул. Амурская, д.&nbsp;225, г. Благовещенск, 675004, ИНН 2801023444, ОГРН
        1022800000079
      </>
    ),
    id: "atb",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ПАО &laquo;АК&nbsp;БАРС&raquo; БАНК&nbsp;&mdash; ул.
        Декабристов, д.&nbsp;1, г. Казань, Республика Татарстан, 420066, ИНН
        1653001805, ОГРН 1021600000124
      </>
    ),
    id: "akbars",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ООО КБЭР &laquo;Банк Казани&raquo;&nbsp;&mdash; ул.
        Солдатская, д.1, г. Казань, 420066, ИНН 1653018661, ОГРН 1021600000014
      </>
    ),
    id: "kazanbank",
  },
  {
    data: (
      <>
        &mdash;&nbsp;Банк &laquo;КУБ&raquo; (АО), ул. Гагарина, д.17, г.
        Магнитогорск, 455044, ИНН 7414006722, ОГРН 1027400000638
      </>
    ),
    id: "credituralbank",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ПАО Банк Синара&nbsp;&mdash; ул. Куйбышева, д.&nbsp;75, г.
        Екатеринбург, 620026, ИНН 6608003052, ОГРН 1026600000460
      </>
    ),
    id: "sinara",
  },
  {
    data: (
      <>
        &mdash;&nbsp;АО&nbsp;&laquo;Банк ДОМ.РФ&raquo;&nbsp;&mdash; ул.
        Воздвиженка, д.&nbsp;10, г. Москва, 125009, ИНН 7725038124, ОГРН
        1037739527077
      </>
    ),
    id: "domrf",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ПАО &laquo;МОСКОВСКИЙ КРЕДИТНЫЙ БАНК&raquo;&nbsp;&mdash;
        Луков переулок, д.&nbsp;2, стр.&nbsp;1, г. Москва, 107045, ИНН
        7734202860, ОГРН 1027739555282
      </>
    ),
    id: "mkb",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ТКБ БАНК ПАО&nbsp;&mdash; ул. Воронцовская, д. 27/35,
        г. Москва, 109147, ИНН 7709129705, ОГРН 1027739186970
      </>
    ),
    id: "tkb",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ПАО РОСБАНК&nbsp;&mdash; ул. Маши Порываевой, д. 34,
        г. Москва, 107078, ИНН 7730060164, ОГРН 1027739460737
      </>
    ),
    id: "rosbank",
  },
  {
    data: (
      <>
        &mdash;&nbsp;Банк &laquo;РЕСО Кредит&raquo; (АО)&nbsp;&mdash; шоссе Воробьевское, д. 6,
        г. Москва,119285, ИНН 7750004305, ОГРН 1087711000046
      </>
    ),
    id: "resokredit",
  },
  {
    data: (
      <>
        &mdash;&nbsp;КБ &laquo;ЛOKO-Банк&raquo; (АО)&nbsp;&mdash; Ленинградский проспект, д. 39, стр. 80,
        г. Москва, 125167, ИНН 7750003943, ОГРН 1057711014195
      </>
    ),
    id: "loko",
  },
  {
    data: (
      <>
        &mdash;&nbsp;ООО &laquo;Камкомбанк&raquo; (АО)&nbsp;&mdash; ул. Гидростроителей, д. 21,
        г. Набережные Челны, Республика Татарстан, 423800, ИНН 1650025163, ОГРН 1021600000840
      </>
    ),
    id: "kamkombank",
  },
  {
    data: (
      <>
          &mdash;&nbsp;ПАО &laquo;Промсвязьбанк&raquo;&nbsp;&mdash; ул. Смирновская, д. 10, строение 22,
          г. Москва, 109052, ИНН 7744000912, ОГРН 1027739019142
      </>
    ),
    id: "psb",
  },
  {
    data: (
      <>
          &mdash;&nbsp;ПАО &laquo;Норвик Банк&raquo;&nbsp;&mdash; ул Зацепский Вал, д. 5,
          г. Москва, 115054, ИНН 4346001485, ОГРН 1024300004739
      </>
    ),
    id: "norvik",
  },
  {
    data: (
      <>
          &mdash;&nbsp;АО &laquo;Свой Банк&raquo;&nbsp;&mdash; ул. Василисы Кожиной, д.&nbsp;1,
          г. Москва, 121096, ИНН 6453031840, ОГРН 1026400001870
      </>
    ),
    id: "svoibank",
  },
  {
    data: (
      <>
          &mdash;&nbsp;АО &laquo;НС&nbsp;Банк&raquo;&nbsp;&mdash; ул. Добровольческая, д.&nbsp;20, стр.&nbsp;2,
          г. Москва, 109004, ИНН 7744001024, ОГРН 1027739198200
      </>
    ),
    id: "nsbank",
  },
  {
    data: (
      <>
        &mdash;&nbsp;КБ&nbsp;&laquo;Энерготрансбанк&raquo; (АО)&nbsp;&mdash; ул. Клиническая, 83а,
        Калининград, 236016, ИНН 3906098008, ОГРН 1023900000080
      </>
    ),
    id: "etb",
  },
]
